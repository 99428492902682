<template>
  <div class="appointments">
    <div class="appointments-container">
      <div v-if="!appointments.length" class="text-center mt-4">
        No appointments
      </div>
      <div v-else>
        <div v-for="item in getData" :key="item">
          <div
            :id="'title ' + item.date"
            class="item-title"
            :class="item.date === currentDate ? 'item-title-today' : ''"
          >
            <h3 class="pa-2" style="font-size: 18px">{{ item.date }}</h3>
          </div>
          <v-list class="pa-0 ma-0">
            <div v-for="(element, index) in item.items" :key="element">
              <v-list-group :value="false">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="ml-2">
                      {{
                        getTimeFormat(
                          element.start,
                          element.timezone,
                          element.title
                        )
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="ml-2">
                      {{ element.facility }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <v-list-item
                  style="background: #e7f4f5"
                  v-if="!isDoxyButtonAvailable(element)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="ml-2">
                      {{ element.address1 }}, {{ element.address2 }}
                      <br />
                      {{ element.city }}, {{ element.state }} {{ element.zip }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <a :href="element.mapslink" target="_blank">
                      <img width="18px" height="24px" :src="getIconMapMarker" />
                    </a>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  style="background: #e7f4f5"
                  v-if="!isDoxyButtonAvailable(element)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="ml-2">
                      {{ phoneFormatted(element.phone) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <a :href="`tel:${element.phone}`">
                      <img width="18px" height="24px" :src="getIconPhone" />
                    </a>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-else>
                  <Btn
                    width="120px"
                    :disabled="!isDoxyButtonClickable(element)"
                    theme="primary"
                    @click="joinDoxy(element)"
                    style="margin: 0 auto"
                  >
                    JOIN NOW
                  </Btn>
                </v-list-item>
                <div
                  style="
                    height: 6px;
                    background: #a0d5d7;
                    border-radius: 0px 0px 16px 16px;
                  "
                ></div>
              </v-list-group>
              <v-divider
                v-if="index < item.items.length - 1"
                :key="element"
              ></v-divider>
            </div>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { calendar } from '../../sharedPlugin';
import _ from 'lodash';
import moment from 'moment';
require('moment-timezone');
export default {
  data: () => ({
    moment
  }),
  computed: {
    ...calendar.mapComputed([
      'selectedDate',
      'appointments',
      'appointmentsLoading'
    ]),
    getData() {
      let appointments = this.appointments;
      let data = [];
      appointments.map((item) => {
        item.date = moment(item.end).format('YYYY-MM-DD');
        return item;
      });
      appointments = _.groupBy(appointments, 'date');
      Object.keys(appointments).forEach((key) => {
        data.push({
          date: moment(key).format('dddd, MMMM DD, YYYY'),
          items: appointments[key]
        });
      });
      return data;
    },
    getIconMapMarker() {
      return require('../../assets/svg/map_marker.svg');
    },
    getIconPhone() {
      return require('../../assets/svg/phone.svg');
    },
    currentDate() {
      return moment().format('dddd, MMMM DD, YYYY');
    }
  },
  methods: {
    ...calendar.mapMethods(['pullAppointmentsAsync']),
    isDoxyButtonClickable(item) {
      const apptStartTime = new Date(item.start);
      const apptEndTime = new Date(item.end);
      const currTime = new Date();

      const startOfDay = new Date(currTime);
      startOfDay.setHours(0, 0, 0, 0);

      const timeDifferenceMidnight =
        apptStartTime.getTime() - startOfDay.getTime();
      const timeDifferenceEnd = currTime.getTime() - apptEndTime.getTime();

      // Check if the current time is between midnight and 1 hour after the appointment ended
      return (
        timeDifferenceMidnight <= 24 * 60 * 60 * 1000 &&
        timeDifferenceEnd <= 60 * 60 * 1000
      );
    },
    isDoxyButtonAvailable(item) {
      if (item.doxylink) {
        return true;
      }

      return false;
    },
    joinDoxy(item) {
      console.log(item);
      if (item.doxylink) {
        console.log('has a doxy link');
        const doxyLink = item.doxylink;
        window.open(doxyLink, '_blank');
      }
    },
    getTimeFormat(date, timezone, title) {
      let start = moment(date).format('h:mma');

      var timeZoneAbbr = moment.tz(moment(), moment.tz.guess()).format('z');
      var abbr = '(' + timeZoneAbbr + ')';

      return `${start} ${abbr} - ${title}`;
    },
    phoneFormatted(phone) {
      if (phone) {
        phone = phone.replace(/[^0-9]/g, '') || '';
        phone = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(
          6,
          10
        )}`;
      }
      return phone;
    }
  },
  created() {
    this.pullAppointmentsAsync();
  },
  mounted() {
    const date = moment(this.selectedDate).format('dddd, MMMM DD, YYYY');
    let element = document.getElementById(`title ${date}`);
    element?.scrollIntoView();
  }
};
</script>

<style lang="scss">
.appointments {
  .appointments-container {
    height: 92vh;
    overflow-y: scroll;
  }
  .item-action {
    font-size: 1rem;
    height: 36px;
    align-items: center;
  }
  .item-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    height: 41px;
    background: #e7f4f5;
    color: #000;
    &-today {
      height: 41px;
      background: #d6366e;
      color: #fff;
    }
  }
  .white-space-nowrap {
    white-space: nowrap;
  }
  .v-list-group {
    .v-icon {
      color: #d6366e;
    }
  }
  .v-list-group.v-list-group--active {
    .v-icon {
      color: #1f6070;
    }
  }
}
</style>
